import React from "react"
import Layout from "Layouts/default"
import Config from "Data"
import styled from "styled-components"
import { SEO, Link } from "Components"
import { FullWrapper, Gap } from "Styles"
import { theme } from "Theme"

const Content = styled.div`
  width: 100%;
  padding: 1rem 0;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    padding: 0;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`

const Terms = () => (
  <Layout locale={"en-us"} region={"us"} sticky={true} transparentHeader={false} transparentFooter={true}>
    <SEO pathname="/terms/" title={`Terms of use | ${Config.brandBusiness} by ${Config.contact.name}`} />
    <Gap />
    <Gap />
    <FullWrapper>
      <Content>
        <h1>Terms of use</h1>
        <br />

        <h3>Acceptance</h3>
        <p>
          Welcome to the {Config.brand} website located at{" "}
          <Link
            to="/"
            style={{
              textDecoration: "underline",
              color: `${theme.colors.primary.base}`,
            }}>
            {Config.siteUrl}
          </Link>{" "}
          together with any materials and services available therein, and successor site(s) thereto or the{" "}
          {Config.brand} Direct mobile application, (the “Site”), which is operated by {Config.contact.legalName}.
          ("us", "we", or "our"). These Terms of Use (“Terms”) state the terms and conditions governing your use of and
          access to the Site and constitute a legally binding contract between you and {Config.company}. These Terms
          incorporate any additional terms and conditions posted by {Config.brand} through the Site, or otherwise made
          available to you by {Config.company}. PLEASE REVIEW THESE TERMS CAREFULLY. YOUR ACCESS TO AND/OR USE OF THE
          SITE CONSTITUTES YOUR ACCEPTANCE OF, AND CONSENT TO BE BOUND BY, THESE TERMS. IF YOU DO NOT ACCEPT AND CONSENT
          TO BE BOUND BY THESE TERMS, THEN YOU MUST NOT USE OR ACCESS THE SITE. We may change these Terms from time to
          time by notifying you of such changes by any reasonable means, including by posting revised Terms through the
          Site. Any such changes will not apply to any dispute between you and us arising prior to the date on which we
          posted the revised Terms incorporating such changes, or otherwise notified you of such changes. The “Last
          Updated” legend above indicates when these Terms were last changed. We may, at any time and without liability,
          modify or discontinue all or part of the Site (including access to the Site via any third-party links); or
          offer opportunities to some or all Site users.
        </p>

        <h3>Eligibility</h3>
        <p>
          By using the Site, you affirm that you are of legal age to enter into these Terms. If you are an individual
          accessing or using the Site on behalf of, or for the benefit of, any corporation, partnership or other entity
          with which you are associated (an “Organization”), then you are agreeing to these Terms on behalf of yourself
          and such Organization, and you represent and warrant that you have the legal authority to bind such
          Organization to these Terms. References to “you” and “your” in these Terms will refer to both the individual
          using the Site and to any such Organization. The Site is controlled or operated (or both) from the United
          States, and is not intended to subject {Config.company} to any non-U.S. jurisdiction or law. The Site may not
          be appropriate or available for use in some non-U.S. jurisdictions. Any use of the Site is at your own risk,
          and you must comply with all applicable laws, rules and regulations in doing so. We may limit the Site’s
          availability at any time, in whole or in part, to any person, geographic area or jurisdiction that we choose.
        </p>

        <h3>License</h3>
        <p>
          Subject to and conditioned on your compliance with these Terms, {Config.company} grants you a limited,
          non-exclusive, non-transferable, non-assignable, revocable license to access and use the Site, and to download
          copies of the materials that we make available for download on the Site, in each case solely for your personal
          and non-commercial use. The Site, including all content, information, and materials incorporated into or made
          available through the Site, is the exclusive property of {Config.company} or its suppliers, and is protected
          by U.S. and international law. You agree not to access or use the Site, or any content, information, or
          materials incorporated into or made available through the Site, except as expressly permitted under these
          Terms. All trademarks, service marks, and logos displayed on the Site (the “Marks”) are the exclusive property
          of {Config.company} or their respective third party owners. Except for your right to view Marks on our Site,
          You are not granted any rights to the Marks. Nothing in the Site grants, by implication, estoppel, or
          otherwise, any license or right to use any Marks displayed on the Site.
        </p>

        <h3>User Submitted Materials</h3>
        <p>
          The Site includes functionality to enable you to upload your résumé and/or other employment- and
          career-related information, and may also enable you to submit comments and materials through interactive
          features such as message boards and other forums, and chatting, commenting and other messaging functionality
          (all such résumés, information, comments, and materials are “Submitted Materials”). For clarity, you retain
          ownership of your Submitted Materials. You hereby grant {Config.company} a worldwide, royalty-free, fully
          paid-up, non-exclusive, perpetual, irrevocable, transferable and fully sublicensable (through multiple tiers)
          license, without additional consideration to you or any third party, to reproduce, distribute, perform and
          display (publicly or otherwise), create derivative works of, adapt, modify and otherwise use and exploit such
          Submitted Materials, in any format or media now known or hereafter developed, and for any purpose (including
          promotional purposes, such as testimonials). In addition, if you provide to us any ideas, proposals,
          suggestions or other materials (“Feedback”), whether related to the Site or otherwise, such Feedback will be
          deemed Submitted Materials, and you hereby acknowledge and agree that such Feedback is not confidential, and
          that your provision of such Feedback is gratuitous, unsolicited and without restriction, and does not place{" "}
          {Config.company} under any fiduciary or other obligation. You represent and warrant that you have all rights
          necessary to grant the licenses granted in this section, and that your Submitted Materials, and your provision
          thereof through and in connection with the Site, are complete and accurate, and are not fraudulent, tortious
          or otherwise in violation of any applicable law or any right of any third party. You further irrevocably waive
          any “moral rights” or other rights with respect to attribution of authorship or integrity of materials
          regarding each Submission that you may have under any applicable law under any legal theory. {Config.company}{" "}
          may (but has no obligation to) screen, monitor, evaluate and remove any Submitted Materials at any time and
          for any reason, or analyze your access to and use of the Site. We may disclose information regarding your
          access to and use of the Site, and the circumstances surrounding such access and use, to anyone for any reason
          or purpose. For clarity, certain information that you provide to {Config.company} through the Site may also be
          governed by {Config.company}’s Privacy Policy, located at the “Privacy Policy”. You represent and warrant that
          any information you provide in connection with the Site is and will remain accurate and complete, and that you
          will maintain and update such information as needed.
        </p>

        <h3>Rules of Conduct</h3>
        <p>
          In connection with the Site, you agree NOT to: Post, transmit or otherwise make available through or in
          connection with the Site any materials that are or may be: (a) threatening, harassing, degrading, hateful or
          intimidating, or otherwise fail to respect the rights and dignity of others; (b) defamatory, libelous,
          fraudulent or otherwise tortious; (c) obscene, indecent, pornographic or otherwise objectionable; or (d)
          protected by copyright, trademark, trade secret, right of publicity or privacy or any other proprietary right,
          without the express prior written consent of the applicable owner. Post, transmit or otherwise make available
          through or in connection with the Site any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other
          computer code, file or program that is or is potentially harmful or invasive or intended to damage or hijack
          the operation of, or to monitor the use of, any hardware, software or equipment (each, a “Virus”). Use the
          Site for any commercial purpose, or for any purpose that is fraudulent or otherwise tortious or unlawful.
          Harvest or collect information about users of the Site. Interfere with or disrupt the operation of the Site or
          the servers or networks used to make the Site available, including by hacking or defacing any portion of the
          Site; or violate any requirement, procedure or policy of such servers or networks. Restrict or inhibit any
          other person from using the Site. Reproduce, modify, adapt, translate, create derivative works of, sell, rent,
          lease, loan, timeshare, distribute or otherwise exploit any portion of (or any use of) the Site except as
          expressly authorized herein. Reverse engineer, decompile or disassemble any portion of the Site, except where
          such restriction is expressly prohibited by applicable law. Remove any copyright, trademark or other
          proprietary rights notice from the Site. Frame or mirror any portion of the Site, or otherwise incorporate any
          portion of the Site into any product or service. Systematically download and store Site content. Use any
          robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index,
          “scrape,” “data mine” or otherwise gather Site content, or reproduce or circumvent the navigational structure
          or presentation of the Site, without {Config.company}’s express prior written consent. Notwithstanding the
          foregoing, and subject to compliance with any instructions posted in the robots.txt file located in the Site’s
          root directory, {Config.company} grants to the operators of public search engines permission to use spiders to
          copy materials from the Site for the sole purpose of (and solely to the extent necessary for) creating
          publicly available, searchable indices of such materials, but not caches or archives of such materials.{" "}
          {Config.company} reserves the right to revoke such permission either generally or in specific cases, at any
          time and without notice.
        </p>

        <h3>Account and Password</h3>
        <p>
          You may need to register for an account to use all or portions of the Site. We may reject, or require that you
          change, any username, password or other information that you provide to us in registering. You, and not{" "}
          {Config.company}, are responsible for any use or misuse of your user name or password. In particular, it is
          your sole responsibility to (a) maintain the confidentiality of your account login and password; (b)
          frequently update and revise your password; and (c) promptly notify {Config.company} if there is any
          unauthorized use of your account or any breach of security.
        </p>

        <h3>Third Party Materials</h3>
        <p>
          The Site may make available or provide links to third party websites, content, or information (“Third Party
          Materials”). {Config.company} does not control, and is not responsible for, any Third Party Materials and the
          availability of any Third Party Materials on the Site does not imply endorsement of, or affiliation with the
          provider of Third Party Materials. Your use of Third Party Materials is at your own risk.
        </p>

        <h3>Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless {Config.company}, and its affiliates, officers, directors,
          agents, and employees from any claims, liabilities, damages, judgments, awards, losses, costs, expenses and
          fees (including attorneys’ fees) arising out of or relating to your access to or use of, or activities in
          connection with, the Site (including any Submitted Materials), or your violation or alleged violation of these
          Terms or the Privacy Policy.
        </p>

        <h3>Disclaimer</h3>
        <p>
          The site is provided on an “as is” and “as available” basis, without any warranties of any kind, and{" "}
          {Config.company} expressly disclaims all warranties, whether express or implied, with respect to the site to
          the fullest extent permissible under applicable law, including warranties of merchantability, fitness for a
          particular purpose, non-infringement, and title, and warranties arising from course of performance, course of
          dealing, or usage or trade. While we try to maintain the timeliness, integrity and security of the Site, we do
          not guarantee that the Site is or will remain updated, complete, correct or secure, or that access to the Site
          will be uninterrupted. The Site may include inaccuracies, errors and materials that violate or conflict with
          these Terms. Additionally, third parties may make unauthorized alterations to the Site. Further, without
          limiting the foregoing, {Config.company} (a) does not guarantee that you will receive any employment or job
          offers through the Site; (b) will not be responsible for any employment offers or listings, employment
          screenings, employment decisions, or actual employment presented by third parties; and (c) is neither your
          employer nor your agent based solely on your usage of the Site. You must use your own judgment in evaluating
          any prospective employers and any Third Party Materials.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
          {Config.company} will not be liable for any indirect, incidental, consequential, special, exemplary or
          punitive damages of any kind, under any contract, tort (including negligence), strict liability or other
          theory, including damages for loss of profits, use or data, loss of other intangibles, or loss of security of
          submitted materials (including unauthorized interception by third parties of any submitted materials), even if
          advised in advance of the possibility of such damages or losses. Without limiting the foregoing,{" "}
          {Config.company} will not be liable for damages of any kind resulting from your use of or inability to use the
          site or from any third party materials, including from any virus that may be transmitted in connection
          therewith. Your sole and exclusive remedy for dissatisfaction with the site is to stop using the site. The
          maximum aggregate liability of {Config.company} for all damages, losses and causes of action, whether in
          contract, tort (including negligence) or otherwise, will be $100. Applicable law may not allow for limitations
          on certain implied warranties, or exclusions or limitations of certain damages; solely to the extent that such
          law applies to you, some or all of the above disclaimers, exclusions or limitations may not apply to you, and
          you may have certain additional rights.
        </p>

        <h3>Termination</h3>
        <p>
          {Config.company} may terminate or suspend your access to the Site at any time, with or without cause or
          notice, including if we believe that you have violated or acted inconsistently with the letter or spirit of
          these Terms. Upon any such termination or suspension, (a) your right to access and use the Site will
          immediately cease; (b) {Config.company} may immediately deactivate or delete your user name, password and
          account; (c) {Config.company} will be under no obligation to maintain or provide you with access to any
          materials associated with your account (including Submitted Materials), and may retain or delete such
          materials in {Config.company}’s sole discretion; and (d) except for the license granted to you to access and
          use the Site, the remaining provisions of these Terms will survive and continue in effect.
        </p>

        <h3>Notice of Copyright Infringement</h3>
        <p>
          The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe
          that material appearing on the internet infringes their rights under U.S. copyright law. If you believe in
          good faith that materials available on the Site infringe your copyright, you (or your agent) may send to{" "}
          {Config.company} a written notice by mail, e-mail or fax, requesting that {Config.company} remove such
          material or block access to it. If you believe in good faith that someone has wrongly filed a notice of
          copyright infringement against you, the DMCA permits you to send to {Config.company} a counter-notice. Notices
          and counter-notices must meet the then-current statutory requirements imposed by the DMCA. See
          http://www.copyright.gov/ for details. Notices and counter-notices must be sent in writing to: Copyright Agent{" "}
          {Config.company} LLC We suggest that you consult your legal advisor before filing a DMCA notice or
          counter-notice.
        </p>

        <h3>Governing Law; Jurisdiction</h3>
        <p>
          These Terms are governed by and will be construed in accordance with the laws of the State of Illinois,
          U.S.A., without regard to its principles of conflicts of law, and regardless of your location. You agree to
          exclusive jurisdiction of the federal and state courts located in Cook County, IL, U.S.A., and waive any
          jurisdictional, venue or inconvenient forum objections to such courts.
        </p>

        <h3>Information or Complaints</h3>
        <p>
          If you have a question or complaint regarding the Site, please send an e-mail to {Config.contact.email}.
          Please note that e-mail communications will not necessarily be secure; accordingly you should not include
          credit card information or other sensitive information in your e-mail correspondence with us.
        </p>

        <h3>General</h3>
        <p>
          These Terms do not, and will not be construed to, create any partnership, joint venture, employer-employee,
          agency or franchisor-franchisee relationship between you and {Config.company}. If any provision of these Terms
          is found to be unlawful, void or for any reason unenforceable, that provision will be deemed severable from
          these Terms and will not affect the validity and enforceability of any remaining provision. You may not
          assign, transfer or sublicense any or all of your rights or obligations under these Terms without our express
          prior written consent. We may assign, transfer or sublicense any or all of our rights or obligations under
          these Terms without restriction. No waiver by either party of any breach or default under these Terms will be
          deemed to be a waiver of any preceding or subsequent breach or default. Any heading, caption or section title
          contained herein is for convenience only, and in no way defines or explains any section or provision. All
          terms defined in the singular will have the same meanings when used in the plural, where appropriate and
          unless otherwise specified. Any use of the term “including” or variations thereof in these Terms will be
          construed as if followed by the phrase “without limitation.” These Terms, including any terms and conditions
          incorporated herein, constitute the entire agreement between you and {Config.company} relating to the subject
          matter hereof, and supersede any and all prior or contemporaneous written or oral agreements or understandings
          between you and {Config.company} relating to such subject matter. Notices to you (including notices of changes
          to these Terms) may be made via posting to the Site or by e-mail (including in each case via links), or by
          regular mail. Without limitation, a printed version of these Terms and of any notice given in electronic form
          will be admissible in judicial or administrative proceedings based upon or relating to these Terms to the same
          extent and subject to the same conditions as other business documents and records originally generated and
          maintained in printed form. {Config.company} will not be responsible for any failure to fulfill any obligation
          due to any cause beyond its control.
        </p>
      </Content>
    </FullWrapper>
    <Gap />
  </Layout>
)

export default Terms
